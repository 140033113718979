import React, { FC } from "react"
import { Link } from "gatsby"
// @ts-ignore
import { menuitem, menubar, logo, menuheader } from "./header.module.css"

const Header: FC<{ siteTitle: string }> = ({ siteTitle }) => (
  <header className={menuheader}>
    <div className={menubar}>
      <div className={logo}>
        <h1 style={{ fontSize: 26, margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>
      <div className={menuitem}>Wie es funktioniert</div>
      <div className={menuitem}>Lösung</div>
      <div className={menuitem}>Preise</div>
      <div className={menuitem}>Über uns</div>
    </div>
  </header>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
